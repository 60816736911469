// src/Settings.tsx
import React, { useState } from 'react';
import { Button, Modal, Upload, notification, UploadProps, Divider, message, Col, Row} from 'antd';

import Papa from 'papaparse';
import { RcFile, UploadFile } from 'antd/es/upload/interface';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { UploadOutlined, DownloadOutlined } from '@ant-design/icons';


import { storage, db } from './firebase';
import { ref, uploadString } from 'firebase/storage';
import { collection, getDocs } from 'firebase/firestore';



const FarmstoreView: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
    const [isPriceModalVisible, setIsPriceModalVisible] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [priceList, setPriceList] = useState<UploadFile[]>([]);
  const [isUploadButtonDisabled, setIsUploadButtonDisabled] = useState(true);
    const [isPriceUploadButtonDisabled, setIsPriceUploadButtonDisabled] = useState(true);
    const [jsonData, setJsonData] = useState<any>(null);
    const [csvData, setCsvData] = useState<any[]>([]);
    const [fileName, setFileName] = useState<string>('');

  const handleOrderClick = () => {
    setIsModalVisible(true);
  };

    const handlePriceClick = () => {
      setIsPriceModalVisible(true);
    };

    
  const handleOk = async () => {
      
      
      
      try {
          
        if (fileList.length > 0) {
          const file = fileList[0].originFileObj as File; // Access the File object

          // Use FileReader to read the file content as text
          const reader = new FileReader();
          reader.onload = async () => {
            const csvText = reader.result as string;

            // Define mapping between CSV headers and JSON keys
            const headerMapping: Record<string, string> = {
              'Product code': 'id',
              'Product name': 'name',
              'Sub category': 'jsonKey1',
              'Unit price(NAD)': 'jsonKey2',
              'Unit cost(NAD)': 'jsonKey3',
              'In hand stock': 'editableValue',
              'Product status': 'jsonKey4',
    //            '0': 'jsonKey8',
    //            '00': 'jsonKey9',
              // Add more mappings as needed
            };

            // Parse CSV text using PapaParse
            const csvData = Papa.parse(csvText, { header: true }).data as any[]; // Assuming CSV has headers

            // Transform CSV data into desired JSON format using mapping
            const jsonData = await Promise.all(csvData.map(async row => {
              const jsonEntry: Record<string, any> = {};
              Object.keys(row).forEach(csvHeader => {
                if (headerMapping[csvHeader] && row[csvHeader] !== undefined && row[csvHeader] !== '') {
                  jsonEntry[headerMapping[csvHeader]] = row[csvHeader];
                }
              });

              // Fetch corresponding Firestore data for 'id'
              if (jsonEntry.id) {
                const docRef = doc(db, 'farmstore', jsonEntry.id);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                  const docData = docSnap.data();
                  jsonEntry.jsonKey3 = docData?.jsonKey3; // Assuming 'jsonKey3' is the relevant field in Firestore
                    jsonEntry.wholesale_size = docData?.wholesale_size;
                    jsonEntry.stock_size = docData?.stock_size;
                    jsonEntry.location = docData?.location;
                    jsonEntry.min = docData?.min;
                }
              }

              return jsonEntry;
            }));

            // Filter out entries with no properties
            const filteredData = jsonData.filter(entry => Object.keys(entry).length > 0);
              
              const specificEntry = {
                name: "system",
                  camp: "farmstore",
              };
              
              const finalData = [specificEntry, ...filteredData];

            // Convert to JSON string
            const jsonString = JSON.stringify(finalData, null, 2);
           

            // Create a blob object
//            const blob = new Blob([jsonString], { type: 'application/json' });

            // Create download link
//            const url = URL.createObjectURL(blob);
//            const a = document.createElement('a');
//            a.href = url;
//            a.download = 'converted_data.json';
//            document.body.appendChild(a);
//            a.click();
//            document.body.removeChild(a);
//            URL.revokeObjectURL(url);
              
              
              const now = new Date();
              const year = now.getFullYear();
              const month = String(now.getMonth() + 1).padStart(2, '0');
              const day = String(now.getDate()).padStart(2, '0');
              const hours = String(now.getHours()).padStart(2, '0');
              const minutes = String(now.getMinutes()).padStart(2, '0');
              const filename = `${year}${month}${day} - Farmstore - ${hours}:${minutes}.json`;

              const storageRef = ref(storage, `stock/${filename}`);
              await uploadString(storageRef, jsonString, 'raw');
              
              
              
              
              
              

            notification.success({ message: 'Farmstore order submitted for review!' });
          };
          reader.readAsText(file); // Pass the File object to readAsText

         
          setFileList([]);
          setIsUploadButtonDisabled(true);
            setIsModalVisible(false);
        }
          
          
          
      } catch (error) {
     
          notification.error({ message: 'Error submitting Farmstore Order for review!' });
      }
      
      
      
      
      
      
      
      
      
      
      
      
      
      
  };
    
    
    
 

    
    
    
    const handlePriceOk = async () => {
        
        
        
        try {
            
          if (fileList.length > 0) {
            const file = fileList[0].originFileObj as File; // Access the File object

            // Use FileReader to read the file content as text
            const reader = new FileReader();
            reader.onload = async () => {
              const csvText = reader.result as string;

              // Define mapping between CSV headers and JSON keys
              const headerMapping: Record<string, string> = {
                'Product code': 'id',
                'Product name': 'name',
                'Sub category': 'jsonKey3',
                'Unit price(NAD)': 'jsonKey4',
                'Unit cost(NAD)': 'jsonKey5',
                'In hand stock': 'editableValue',
                'Product status': 'jsonKey7',
      //            '0': 'jsonKey8',
      //            '00': 'jsonKey9',
                // Add more mappings as needed
              };

              // Parse CSV text using PapaParse
              const csvData = Papa.parse(csvText, { header: true }).data as any[]; // Assuming CSV has headers

              // Transform CSV data into desired JSON format using mapping
              const jsonData = await Promise.all(csvData.map(async row => {
                const jsonEntry: Record<string, any> = {};
                Object.keys(row).forEach(csvHeader => {
                  if (headerMapping[csvHeader] && row[csvHeader] !== undefined && row[csvHeader] !== '') {
                    jsonEntry[headerMapping[csvHeader]] = row[csvHeader];
                  }
                });

                // Fetch corresponding Firestore data for 'id'
                if (jsonEntry.id) {
                  const docRef = doc(db, 'farmstore', jsonEntry.id);
                  const docSnap = await getDoc(docRef);
                  if (docSnap.exists()) {
                    const docData = docSnap.data();
                    jsonEntry.jsonKey3 = docData?.jsonKey3; // Assuming 'jsonKey3' is the relevant field in Firestore
                      jsonEntry.wholesale_size = docData?.wholesale_size;
                      jsonEntry.stock_size = docData?.stock_size;
                      jsonEntry.location = docData?.location;
                      jsonEntry.min = docData?.min;
                  }
                }

                return jsonEntry;
              }));

              // Filter out entries with no properties
              const filteredData = jsonData.filter(entry => Object.keys(entry).length > 0);
                
                const specificEntry = {
                  name: "system",
                    camp: "farmstore",
                };
                
                const finalData = [specificEntry, ...filteredData];

              // Convert to JSON string
              const jsonString = JSON.stringify(finalData, null, 2);
                setJsonData(jsonString);
                
                
                console.log("xxx")
                
                
                if (jsonData) {
                    console.log("xx")
                    const csv = Papa.unparse(jsonData);
                    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
                    const url = URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = 'data.csv';
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    notification.success({ message: 'YESS' });
                    console.log("x")
                  } else {
                  
                      notification.error({ message: 'No JSON data to convert' });
                  }
                
                

//              // Create a blob object
//              const blob = new Blob([jsonString], { type: 'application/json' });
//
//            // Create download link
//              const url = URL.createObjectURL(blob);
//              const a = document.createElement('a');
//              a.href = url;
//              a.download = 'converted_data.json';
//              document.body.appendChild(a);
//              a.click();
//              document.body.removeChild(a);
//              URL.revokeObjectURL(url);
                
                
//                const now = new Date();
//                const year = now.getFullYear();
//                const month = String(now.getMonth() + 1).padStart(2, '0');
//                const day = String(now.getDate()).padStart(2, '0');
//                const hours = String(now.getHours()).padStart(2, '0');
//                const minutes = String(now.getMinutes()).padStart(2, '0');
//                const filename = `${year}${month}${day} - Farmstore - ${hours}:${minutes}.json`;
//
//                const storageRef = ref(storage, `stock/${filename}`);
//                await uploadString(storageRef, jsonString, 'raw');
                
              notification.success({ message: 'Farmstore price list generated!' });
            };
            reader.readAsText(file); // Pass the File object to readAsText
            setPriceList([]);
            setIsPriceUploadButtonDisabled(true);
              setIsPriceModalVisible(false);
          }
        } catch (error) {
            notification.error({ message: 'Error generating Farmstore price list!' });
        }
    };
    
    
    

  const handleCancel = () => {
    setIsModalVisible(false);
    setFileList([]);
    setIsUploadButtonDisabled(true);
  };

    const handlePriceCancel = () => {
        setIsPriceModalVisible(false);
      setPriceList([]);
      setIsPriceUploadButtonDisabled(true);
    };

  const handleUploadChange: UploadProps['onChange'] = (info) => {
    const isCSV = info.file.type === 'text/csv';
    const isSmall = info.file.size !== undefined && info.file.size / 1024 / 1024 < 1;

    if (!isCSV) {
      notification.error({ message: 'You can only upload CSV files!' });
      return;
    }
    if (!isSmall) {
      notification.error({ message: 'File must be smaller than 1MB!' });
      return;
    }

    setFileList(info.fileList);
    setIsUploadButtonDisabled(false);
  };
    
    const handlePriceUploadChange: UploadProps['onChange'] = (info) => {
      const isCSV = info.file.type === 'text/csv';
      const isSmall = info.file.size !== undefined && info.file.size / 1024 / 1024 < 1;

      if (!isCSV) {
        notification.error({ message: 'You can only upload CSV files!' });
        return;
      }
      if (!isSmall) {
        notification.error({ message: 'File must be smaller than 1MB!' });
        return;
      }

      setPriceList(info.fileList);
      setIsPriceUploadButtonDisabled(false);
    };
    
    const handleUpload = (file: File) => {
      setFileName(file.name);
      Papa.parse(file, {
        header: true,
        complete: (results) => {
          setCsvData(results.data);
          message.success(`${file.name} file uploaded successfully.`);
        },
        error: (error) => {
          message.error(`Failed to parse CSV file: ${error.message}`);
        },
      });

      // Returning false prevents antd from automatically uploading the file
      return false;
    };

    const handleProcess = () => {
      const processedData = csvData.map((row) => {
        if (row['sub_category'] === 'HUNDRED') {
          row['unit_selling_price'] = (Math.ceil(parseFloat(row['unit_cost']) * 2)).toString();
        } else if (row['sub_category'] === 'FIFTY') {
          row['unit_selling_price'] = (Math.ceil(parseFloat(row['unit_cost']) * 1.5)).toString();
        }else if (row['sub_category'] === 'THIRTY') {
            row['unit_selling_price'] = (Math.ceil(parseFloat(row['unit_cost']) * 1.3)).toString();
          }else if (row['sub_category'] === 'TWENTY') {
              row['unit_selling_price'] = (Math.ceil(parseFloat(row['unit_cost']) * 1.2)).toString();
            }else if (row['sub_category'] === 'TEN') {
                row['unit_selling_price'] = (Math.ceil(parseFloat(row['unit_cost']) * 1.1)).toString();
              }else if (row['sub_category'] === 'ZERO') {
                  row['unit_selling_price'] = (Math.ceil(parseFloat(row['unit_cost']) * 1)).toString();
                }
        return row;
      });

      const csv = Papa.unparse(processedData);
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = `processed_${fileName}`;
      document.body.appendChild(a); // Append to the document body
      a.click();
      document.body.removeChild(a); // Clean up after click
      URL.revokeObjectURL(url);

      message.success('CSV file processed and downloaded successfully.');
    };

    const uploadProps = {
      name: 'file',
      multiple: false,
      accept: '.csv',
      showUploadList: false,
      beforeUpload: handleUpload, // Handle the file upload
    };
    
    

  return (
    <div>
      <h2>Farmstore</h2>
          
          <Row>
               <Col span={12}>
          
          <h3>PRICING for Farmstore</h3>
              <p>Step 1: Go to <a href="https://cloud.salesplaypos.com/" target="_blank" rel="noopener noreferrer">cloud.salesplaypos.com</a> and login.</p>
              <p>Step 2: Go to Products - Product List</p>
              <p>Step 3: Click on Export - Import CSV</p>
                  
                  <div style={{display: 'flex', alignItems: 'center'}}>
              <p>Step 4: Upload CSV file here:</p>
              <Button onClick={handlePriceClick}>PRICING for Farmstore</Button>
                  </div>
                  
                  <p>Step 5: Go to <a href="https://cloud.salesplaypos.com/" target="_blank" rel="noopener noreferrer">cloud.salesplaypos.com</a> and login.</p>
                  <p>Step 6: Go to Products - Product List</p>
              <p>Step 7: Click on Import</p>
              <p>Step 8: Upload processed File</p>
              <p>Step 9: Click on Upload</p>
              <p>Step 10: Press Confirm Import</p>
          
          </Col>
               <Col span={12}>
          
        
          
          <h3>ORDER for Farmstore</h3>
          <p>Step 1: Go to <a href="https://cloud.salesplaypos.com/" target="_blank" rel="noopener noreferrer">cloud.salesplaypos.com</a> and login.</p>
          <p>Step 2: Go to Products - Product List</p>
          <p>Step 3: Click on Export - CSV</p>
              
              <div style={{display: 'flex', alignItems: 'center'}}>
          <p>Step 4: Upload CSV file here:</p>
          <Button onClick={handleOrderClick}>ORDER for Farmstore</Button>
              </div>
              
              <p>Step 5: Review Order (Orders to Review)</p>
              <p>Step 6: Send Order (Past Orders)</p>
          
          </Col>
             </Row>
          
          
          
     
          
    
          
     

      <Modal title="Upload CSV" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} okButtonProps={{ disabled: isUploadButtonDisabled }}>
          <p>Please upload ONLY files like this: "Product-list-YYYY-MM-DD.csv"</p>
          <Upload accept=".csv" beforeUpload={() => false} fileList={fileList} maxCount={1} onChange={handleUploadChange}>
          <Button icon={<UploadOutlined />}>Upload CSV</Button>
        </Upload>
      </Modal>
              
              <Modal title="Calculate Farmstore Pricing" visible={isPriceModalVisible} onOk={handlePriceOk} onCancel={handlePriceCancel} okButtonProps={{ disabled: isPriceUploadButtonDisabled }}>
                  <p>Please upload ONLY files like this: "Import-Product-list-YYYY-MM-DD.csv"</p>
              <div style={{ margin: '20px' }}>
                <Upload {...uploadProps}>
                  <Button icon={<UploadOutlined />}>Upload CSV</Button>
                </Upload>
                <Button
                  type="primary"
                  onClick={handleProcess}
                  disabled={csvData.length === 0}
                  style={{ marginTop: '20px' }}
                >
                  <DownloadOutlined /> Process Farmstore Pricing
                </Button>
              </div>
              </Modal>
                      
                      
    </div>
  );
};

export default FarmstoreView;
