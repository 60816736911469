// src/EditDatabaseView.tsx
import React from 'react';
import { Card, Col, Row, Space} from 'antd';

import DatabaseOverview from './DatabaseOverview';
import AddRecord from './AddRecordForm';
import EditDatabase from './EditDatabase';

const EditDatabaseView: React.FC = () => {
  return (
          <div>
          
     
          <Row gutter={16}>
              <Col span={8}>
          
          <Space direction="vertical" size="middle" style={{ display: 'flex' }} >
                <Card title="Database Overview" bordered={true}>
                    <DatabaseOverview />
                </Card>
         
          <Card title="Add Records to Database" bordered={false}>
          <AddRecord />
          </Card>
          </Space>
          
              </Col>
              <Col span={16}>
                <Card title="Edit Database Records" bordered={false}>
                    <EditDatabase />
                </Card>
              </Col>

            </Row>

    </div>
  );
};

export default EditDatabaseView;
