// src/DatabaseOverview.tsx
import React from 'react';

const Overview: React.FC = () => {
  return (
    <div>
      <p>This is the Overview section of the Database.</p>
          <p>Wholesale Size should be the package size in which the quantities are ordered at wholesale</p>
          <p>Stock Size schould be the size in which the objects are counted.</p>
          <p>Minimum refers to the stock size</p>
    </div>
  );
};

export default Overview;
