import React, { useEffect, useState, useCallback } from 'react';
import { List, Button, notification, Spin, Modal, Empty } from 'antd';
import { ExclamationCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { storage } from './firebase';
import { ref, listAll, getDownloadURL, getBlob, getMetadata, deleteObject } from 'firebase/storage';
import DataTable from './DataTable';
import { DownloadOutlined, DeleteOutlined } from '@ant-design/icons';



interface FileMetadata {
  name: string;

  url: string;
  timeCreated: string;
}

const spinnerContainerStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '200px',
};

const OrdersToReview: React.FC = () => {
  const [groupedFiles, setGroupedFiles] = useState<{ [date: string]: FileMetadata[] }>({});
  const [loading, setLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedData, setSelectedData] = useState<any>(null);

  const fetchFiles = useCallback(async () => {
    setLoading(true);
    try {
      const listRef = ref(storage, 'stock');
      const res = await listAll(listRef);

      const filesWithMetadata = await Promise.all(
        res.items.map(async itemRef => {
          const url = await getDownloadURL(itemRef);
          const metadata = await getMetadata(itemRef);
          return { name: itemRef.name, url, timeCreated: metadata.timeCreated };
        })
      );

      const grouped: { [date: string]: FileMetadata[] } = {};
      filesWithMetadata.forEach(file => {
        const date = new Date(file.timeCreated).toISOString().split('T')[0];
        if (!grouped[date]) {
          grouped[date] = [];
        }
        grouped[date].push(file);
      });

      Object.keys(grouped).forEach(date => {
        grouped[date].sort((a, b) => new Date(b.timeCreated).getTime() - new Date(a.timeCreated).getTime());
      });

      setGroupedFiles(grouped);
    } catch (error) {
      notification.error({ message: 'Error fetching files', description: (error as Error).message });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchFiles();
  }, [fetchFiles]);

  const handleEdit = useCallback(async (fileName: string) => {
    try {
//        console.log("xx");
      const fileRef = ref(storage, `stock/${fileName}`);
      const fileBlob = await getBlob(fileRef);
      const fileText = await fileBlob.text();
      const fileData = JSON.parse(fileText);
      setSelectedData(fileData);
      setModalVisible(true);
        console.log("xxx");
    } catch (error) {
      notification.error({ message: 'Error fetching file data', description: (error as Error).message });
    }
  }, []);

  const handleRemove = useCallback(async (fileName: string) => {
    const fileRef = ref(storage, `stock/${fileName}`);

    Modal.confirm({
      title: 'Are you sure you want to delete this file?',
      icon: <ExclamationCircleOutlined />,
      content: 'This action cannot be undone.',
      onOk: async () => {
        try {
          await deleteObject(fileRef);
          notification.success({ message: 'File deleted successfully' });
          await fetchFiles();
        } catch (error) {
          notification.error({ message: 'Error deleting file', description: (error as Error).message });
        }
      },
      onCancel() {
        console.log('Cancelled');
      },
    });
  }, [fetchFiles]);

  const handleModalClose = () => {
    setModalVisible(false);
    setSelectedData(null);
  };

    
    const renderContent = () => {
      if (loading) {
        return <Spin size="large" />;
      }

      if (Object.keys(groupedFiles).length === 0) {
        return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
      }

      return Object.keys(groupedFiles)
        .sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
        .map(date => (
          <div key={date}>
            <h2>{date}</h2>
            <List
              bordered
              dataSource={groupedFiles[date]}
              renderItem={(item) => (
                <List.Item>
                  <a href={item.url} target="_blank" rel="noopener noreferrer">{item.name}</a>
                  <div style={{ marginLeft: 'auto' }}>
                    <Button
                      type="primary"
                      icon={<SearchOutlined />}
                                     shape="round"
                      onClick={() => handleEdit(item.name)}
                      style={{ marginRight: '8px' }}
                    >
                      Review
                    </Button>

                                     <Button type="primary" danger shape="round" style={{ marginLeft: '8px' }} icon={<DeleteOutlined />} onClick={() => handleRemove(item.name)}>
                                       Remove
                                     </Button>
                  </div>
                </List.Item>
              )}
            />
          </div>
        ));
    };
    
    return (
            <div>
      <div style={loading ? spinnerContainerStyle : undefined}>
        {renderContent()}
      </div>
            <Modal
                    title="Review Order"
                    visible={modalVisible}
                    onCancel={handleModalClose}
                    footer={null}
                    width={800} // Adjust the width here as needed
                  >
                    <DataTable initialValues={selectedData} />
                  </Modal>
            </div>
    );
};

export default OrdersToReview;
