import React, { useEffect, useState, useRef } from 'react';
import { Table, Form, InputNumber, Button, Popconfirm, notification, Result} from 'antd';
import { SmileOutlined } from '@ant-design/icons';


import { storage } from './firebase';
import { ref, uploadString } from 'firebase/storage';

import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

interface DataItem {
  id: number;
camp: string;
  section: string;
  min: number;
  editableValue: number;
  orderValue?: number;
  wholesale_size: string;
  stock_size: string;
  name: string;
  layoutOrder: number;
  orderFor: string;
  location: string;
}

interface DataTableProps {
  initialValues: DataItem[];
}

const DataTable: React.FC<DataTableProps> = ({ initialValues }) => {
  const [data, setData] = useState<DataItem[]>([]);
  const [guests, setGuests] = useState(0);
  const [errors, setErrors] = useState(0);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const forceUpdate = useRef(0);
    const [submittingOrder, setSubmittingOrder] = useState(false);
    const [orderSubmitted, setOrderSubmitted] = useState(false);

  useEffect(() => {
    if (initialValues.length > 0) {
      const sortedData = initialValues.slice(1).sort((a: DataItem, b: DataItem) => (a.layoutOrder ?? 0) - (b.layoutOrder ?? 0));
      setData(sortedData);
      setFormSubmitted(false);
    }
  }, [initialValues]);

  const extractNumber = (text: string) => {
    const regex = /\((\d+)x/;
    const match = text.match(regex);
    return match ? parseInt(match[1], 10) : null;
  };

  const generateJSONData = (): string => {
    const modifiedData = data.map(item => ({ ...item, editableValue: item.editableValue ?? 0 }));
    return JSON.stringify(modifiedData);
  };

  const saveToFirebase = async () => {
    try {
        
        setSubmittingOrder(true); // Set loading state to true
        
      const jsonData = generateJSONData();

      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0');
      const day = String(now.getDate()).padStart(2, '0');
      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');
        
        const camp = (initialValues[0]?.camp).toUpperCase();
      const baseFilename = `${camp} - ${year}${month}${day} - ${hours}:${minutes}`;

      const locations = ['SPAR', 'ERONGO_WHOLESALE', 'OTHER'];
      for (const location of locations) {
        const locationData = data.filter(item => item.location === location);
        if (locationData.length > 0) {
          const pdf = generatePDF(locationData, location);
          const pdfBlob = pdf.output('blob');
          const storageRef = ref(storage, `orders/${baseFilename} - ${location}.pdf`);
          await uploadString(storageRef, await pdfBlob.text(), 'raw');
        }
      }
        setOrderSubmitted(true);
      notification.success({ message: 'Order reviewed successfully!' });
    } catch (error) {
      notification.error({ message: 'Error reviewing order!', description: (error as Error).message });
    }finally {
        setSubmittingOrder(false); // Set loading state back to false
      }
  };

  const generatePDF = (data: DataItem[], location: string) => {
    const doc = new jsPDF();
      
      const camp = (initialValues[0]?.camp).toUpperCase();

    doc.text(`Omandumba ${camp} - ${location}`, 14, 20);
    const tableColumn = ['Quantity', 'Size', 'Item'];
    const tableRows: (string | number)[][] = [];

    data.forEach(item => {
      const itemData = [
       
          item.orderValue || 0,
          item.wholesale_size,
          item.name,
       
       
       
      ];
      tableRows.push(itemData);
    });

    autoTable(doc, {
      head: [tableColumn],
      body: tableRows,
      startY: 30,
    });

    return doc;
  };

  const handleSubmit = (values: { guests: number; error: number }) => {
    const { guests, error } = values;

    const newData = data.map((item) => {
      if (item.section === 'Fresh') {
        const rawValue = error * 7 + guests;
        const preValue = Math.ceil(parseFloat(item.min.toString().replace(',', '.')) * Number(rawValue));
        const orderValue = Math.max(Number(preValue) - Number(item.editableValue), 0);
        return { ...item, orderValue };
      }  else if (item.wholesale_size === item.stock_size) {
        const preValue = item.min - item.editableValue;
        return { ...item, orderValue: Math.max(preValue, 0) };
      } else {

          if (Number(item.editableValue) >= Number(item.min)) {
            return { ...item, orderValue: 0 };
          }else{
              const packsize = extractNumber(item.wholesale_size);
              if (packsize) {
                  const preValue = Number(item.min) - Number(item.editableValue);
                  const orderValue = Math.ceil(Number(preValue) / Number(packsize));
                  
                  return { ...item, orderValue };
              } else {
                  notification.error({ message: 'Error', description: 'Some items have been removed as the wholesize pack size is not specified correctly.' });
                  return { ...item, orderValue: 0 };
              }
          }
          
      }
    });

    const filteredData = newData.filter(item => item.orderValue !== 0);

    setData(filteredData);
    setGuests(guests);
    setErrors(error);
    forceUpdate.current += 1;
    setFormSubmitted(true);
  };

  const handleDelete = (id: number) => {
    const newData = data.filter((item) => item.id !== id);
    setData(newData);
  };

  const handleEditableValueChange = (value: number, id: number) => {
    const newData = data.map((item) => (item.id === id ? { ...item, editableValue: value } : item));
    setData(newData);
  };

  const handleOrderValueChange = (value: number, id: number) => {
    const newData = data.map((item) => (item.id === id ? { ...item, orderValue: value } : item));
    setData(newData);
  };

  const columns = [
    {
      title: 'STOCK',
      dataIndex: 'editableValue',
      key: 'editableValue',
      render: (text: any, record: any) => (
        <InputNumber
          value={record.editableValue}
          onChange={(value) => handleEditableValueChange(value as number, record.id)}
          disabled={true}
        />
      ),
    },
    {
      title: 'ORDER',
      dataIndex: 'orderValue',
      key: 'orderValue',
      render: (text: any, record: any) => (
        <InputNumber
          min={0}
          value={record.orderValue}
          onChange={(value) => handleOrderValueChange(value as number, record.id)}
        />
      ),
    },
    {
      title: 'Size',
      dataIndex: 'wholesale_size',
      key: 'wholesale_size',
    },
    {
      title: 'Item',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Layout Order',
      dataIndex: 'layoutOrder',
      key: 'layoutOrder',
      sorter: (a: DataItem, b: DataItem) => a.layoutOrder - b.layoutOrder,
      hidden: true,
    },
    {
      title: 'Action',
      key: 'action',
      render: (text: any, record: any) => (
        <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.id)}>
          <Button type="link">Delete</Button>
        </Popconfirm>
      ),
    },
  ];


          return (
             <div>
               {orderSubmitted ? (
                                  <div>
                                  <Result
                                    icon={<SmileOutlined />}
                                    title="Thank you, for reviewing the order!"
                                  />
                                  </div>
                                  
                                  
               ) : (
                 <div>
                   <div style={{ marginBottom: '16px' }}>
                     <p>Order submitted by: {initialValues[0]?.name}</p>
                     <Form
                       name="test-form"
                       initialValues={{ error: 0, guests: 0 }}
                       onFinish={handleSubmit}
                     >
                       <Form.Item
                         name="guests"
                         label="Guests"
                         rules={[
                           { required: true, message: 'Please specify number of guests!' },
                         ]}
                       >
                         <InputNumber min={0} disabled={formSubmitted} />
                       </Form.Item>

                       <Form.Item
                         name="error"
                         label="Error"
                         rules={[
                           { required: true, message: 'Please specify the error value!' },
                         ]}
                       >
                         <InputNumber min={0} disabled={formSubmitted} />
                       </Form.Item>

                       {!formSubmitted && (
                         <Form.Item>
                           <Button type="primary" htmlType="submit">
                             Submit
                           </Button>
                         </Form.Item>
                       )}
                     </Form>
                   </div>
                   {formSubmitted && data && (
                     <>
                       <Table
                         dataSource={data}
                         columns={columns}
                         rowKey="id"
                         pagination={false}
                         loading={false}
                         key={forceUpdate.current}
                       />
                       <Button type="primary" onClick={saveToFirebase} disabled={submittingOrder} loading={submittingOrder}>
                         {submittingOrder ? 'Submitting...' : 'Submit Order'}
                       </Button>
                     </>
                   )}
                 </div>
               )}
             </div>
           );
                     };

                     export default DataTable;
