// src/AddRecordForm.tsx
import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, notification } from 'antd';
import { doc, setDoc, addDoc, collection } from 'firebase/firestore';
import { db, auth } from './firebase';
import { User, onAuthStateChanged } from 'firebase/auth';
import './App.css';

const { Option } = Select;

interface FormValues {
  collection: string;
  section: string;
  location: string;
  name: string;
  wholesale_size: string;
  stock_size: string;
  min: string;
  weight?: string; // Optional field
  comment?: string;
  id?: string; // Optional field
}

const AddRecordForm: React.FC = () => {
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const [section, setSection] = useState<string>('');
  const [optionVal, setOptionVal] = useState<string>('');

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  const onFinish = async (values: FormValues) => {
    if (!user) {
      notification.error({ message: 'Error', description: 'You must be signed in to add records.' });
      return;
    }

    setSubmitting(true);
    try {
      const { section, location, name, wholesale_size, stock_size, min, collection: selectedCollection, weight, id } = values;
      const documentData = { section, location, name, wholesale_size, stock_size, min, ...(section === 'Fresh' && { weight }) };

      if (optionVal === 'farmstore' && id) {
        await setDoc(doc(db, selectedCollection, id), documentData);
      } else {

          await addDoc(collection(db, selectedCollection ), documentData);
      }

      notification.success({
        message: 'Success',
        description: 'Record added successfully!',
      });
      form.resetFields();
      setSection('');
      setOptionVal('');
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'Failed to add record',
      });
      console.error('Error adding document: ', error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleSectionChange = (value: string) => {
    setSection(value);
    if (value !== 'Fresh') {
      form.setFieldsValue({ weight: undefined });
    }
  };

  const handleOptionChange = (value: string) => {
    setOptionVal(value);
    if (value !== 'farmstore') {
      form.setFieldsValue({ id: undefined });
    }
  };

  return (
          
          <div>
    <Form form={form} name="add_record" layout="vertical" onFinish={onFinish}>
          
          
      <Form.Item
        name="collection"
        label="Select collection"
        rules={[{ required: true, message: 'Field required!' }]}
      >
        <Select placeholder="Select a collection" onChange={handleOptionChange}>
          <Option value="bushcamp">Bushcamp</Option>
          <Option value="farmstore">Farmstore</Option>
          <Option value="guestfarm">Guestfarm</Option>
        </Select>
      </Form.Item>
          
          
          <div>
          {optionVal === 'farmstore' && (
                                         <div>
                                         <Form.Item
                                           name="section"
                                           label="Select a section"
                                           rules={[{ required: true, message: 'Field required!' }]}
                                         >
                                           <Select placeholder="Select a section" onChange={handleSectionChange}>
                                             <Option value="OSTORA">Ostora</Option>
                                           </Select>
                                         </Form.Item>
                                         
                                         <Form.Item
                                           name="id"
                                           label="ID"
                                           rules={[{ required: true, message: 'Field required!' }]}
                                         >
                                           <Input />
                                         </Form.Item>
                                         
                                         <Form.Item
                                           name="location"
                                           label="Select a location"
                                           rules={[{ required: true, message: 'Field required!' }]}
                                         >
                                           <Select placeholder="Select a location">
                                             <Option value="ERONGO_WHOLESALE">ERONGO WHOLESALE</Option>
                                             <Option value="SPAR">SPAR</Option>
                                             <Option value="OTHER">OTHER</Option>
                                           </Select>
                                         </Form.Item>

                                         <Form.Item
                                           name="name"
                                           label="Item Name"
                                           rules={[{ required: true, message: 'Field required!' }]}
                                         >
                                           <Input />
                                         </Form.Item>
                                         <Form.Item
                                           name="wholesale_size"
                                           label="Wholesale size x(1x1)"
                                           rules={[{ required: true, message: 'Field required!' }]}
                                         >
                                           <Input />
                                         </Form.Item>
                                         <Form.Item
                                           name="stock_size"
                                           label="Stock size x(1x1)"
                                           rules={[{ required: true, message: 'Field required!' }]}
                                         >
                                           <Input />
                                         </Form.Item>
                                         
                                         <Form.Item
                                           name="min"
                                           label="Minimum"
                                           rules={[{ required: true, message: 'Field required!' }]}
                                         >
                                           <Input />
                                         </Form.Item>
                                         

                                         <Form.Item name="comment" label="Comment">
                                           <Input />
                                         </Form.Item>
                                         </div>
                                         
          )}
          </div>
          
          
          <div>
          {optionVal === 'bushcamp' && (
                                         
                                         
                                        <div>
                          
                                         
                                        <Form.Item
                                          name="section"
                                          label="Select a section"
                                          rules={[{ required: true, message: 'Field required!' }]}
                                        >
                                          <Select placeholder="Select a section" onChange={handleSectionChange}>
                                            <Option value="Fresh">Fresh</Option>
                                            <Option value="Storeroom">Storeroom</Option>
                                            <Option value="Deepfreezer">Deepfreezer</Option>
                                          </Select>
                                        </Form.Item>
                                        
                                        
                                         
                                        {section === 'Fresh' && (
                                                    <Form.Item
                                                      name="min"
                                                      label="Weight"
                                                      rules={[{ required: true, message: 'Field required!' }]}
                                                    >
                                                      <Input />
                                                    </Form.Item>
                                                  )}
                                        {section === 'Storeroom' && (
                                                                              <Form.Item
                                                                                name="min"
                                                                                label="Minimum"
                                                                                rules={[{ required: true, message: 'Field required!' }]}
                                                                              >
                                                                                <Input />
                                                                              </Form.Item>
                                                 )}
                                                 
                                                 {section === 'Deepfreezer' && (
                                                                                <Form.Item
                                                                                  name="min"
                                                                                  label="Minimum"
                                                                                  rules={[{ required: true, message: 'Field required!' }]}
                                                                                >
                                                                                  <Input />
                                                                                </Form.Item>
                                                 )}
                                         
                                         <Form.Item
                                           name="location"
                                           label="Select a location"
                                           rules={[{ required: true, message: 'Field required!' }]}
                                         >
                                           <Select placeholder="Select a location">
                                             <Option value="ERONGO_WHOLESALE">ERONGO WHOLESALE</Option>
                                             <Option value="SPAR">SPAR</Option>
                                             <Option value="OTHER">OTHER</Option>
                                           </Select>
                                         </Form.Item>

                                         <Form.Item
                                           name="name"
                                           label="Item Name"
                                           rules={[{ required: true, message: 'Field required!' }]}
                                         >
                                           <Input />
                                         </Form.Item>
                                         <Form.Item
                                           name="wholesale_size"
                                           label="Wholesale size x(1x1)"
                                           rules={[{ required: true, message: 'Field required!' }]}
                                         >
                                           <Input />
                                         </Form.Item>
                                         <Form.Item
                                           name="stock_size"
                                           label="Stock size x(1x1)"
                                           rules={[{ required: true, message: 'Field required!' }]}
                                         >
                                           <Input />
                                         </Form.Item>

                                         <Form.Item name="comment" label="Comment">
                                           <Input />
                                         </Form.Item>
                                        </div>
                                         
          )}
          
          </div>
          

     
          <div>
          {optionVal === 'guestfarm' && (
                                         
                                         
                                        <div>
                          
                                         
                                        <Form.Item
                                          name="section"
                                          label="Select a section"
                                          rules={[{ required: true, message: 'Field required!' }]}
                                        >
                                          <Select placeholder="Select a section" onChange={handleSectionChange}>
                                            <Option value="Fresh">Fresh</Option>
                                            <Option value="Storeroom">Storeroom</Option>
                                            <Option value="Deepfreezer">Deepfreezer</Option>
                                          </Select>
                                        </Form.Item>
                                        
                                        
                                         
                                        {section === 'Fresh' && (
                                                    <Form.Item
                                                      name="min"
                                                      label="Weight"
                                                      rules={[{ required: true, message: 'Field required!' }]}
                                                    >
                                                      <Input />
                                                    </Form.Item>
                                                  )}
                                        {section === 'Storeroom' && (
                                                                              <Form.Item
                                                                                name="min"
                                                                                label="Minimum"
                                                                                rules={[{ required: true, message: 'Field required!' }]}
                                                                              >
                                                                                <Input />
                                                                              </Form.Item>
                                                 )}
                                                 
                                                 {section === 'Deepfreezer' && (
                                                                                <Form.Item
                                                                                  name="min"
                                                                                  label="Minimum"
                                                                                  rules={[{ required: true, message: 'Field required!' }]}
                                                                                >
                                                                                  <Input />
                                                                                </Form.Item>
                                                 )}
                                         
                                         <Form.Item
                                           name="location"
                                           label="Select a location"
                                           rules={[{ required: true, message: 'Field required!' }]}
                                         >
                                           <Select placeholder="Select a location">
                                             <Option value="ERONGO_WHOLESALE">ERONGO WHOLESALE</Option>
                                             <Option value="SPAR">SPAR</Option>
                                             <Option value="OTHER">OTHER</Option>
                                           </Select>
                                         </Form.Item>

                                         <Form.Item
                                           name="name"
                                           label="Item Name"
                                           rules={[{ required: true, message: 'Field required!' }]}
                                         >
                                           <Input />
                                         </Form.Item>
                                         <Form.Item
                                           name="wholesale_size"
                                           label="Wholesale size x(1x1)"
                                           rules={[{ required: true, message: 'Field required!' }]}
                                         >
                                           <Input />
                                         </Form.Item>
                                         <Form.Item
                                           name="stock_size"
                                           label="Stock size x(1x1)"
                                           rules={[{ required: true, message: 'Field required!' }]}
                                         >
                                           <Input />
                                         </Form.Item>

                                         <Form.Item name="comment" label="Comment">
                                           <Input />
                                         </Form.Item>
                                        </div>
                                         
          )}
          
          </div>
     

      <Form.Item>
        <Button type="primary" htmlType="submit" disabled={submitting}>
          Add Record
        </Button>
      </Form.Item>
    </Form>
          
          </div>
          
  );
};

export default AddRecordForm;
