import React, { useEffect, useState , useCallback} from 'react';
import { Table, Button, Select, notification, Spin, Modal, Form, Input } from 'antd';
import { collection, getDocs, updateDoc, doc, writeBatch,  deleteDoc} from 'firebase/firestore';
import { db } from './firebase'; // Ensure correct path to your Firebase setup
//import StockEnd from './StockEnd';
import { ColumnType } from 'antd/lib/table';
import { InputNumber, Empty } from 'antd';
import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { MenuOutlined } from '@ant-design/icons';

interface EditDatabaseProps {}

interface MyData {
  id: string;
  section?: string;
  stock_size?: string;
  name?: string;
  layoutOrder?: number;
    comment?: string;
}

const { Option } = Select;
const EditDatabase: React.FC<EditDatabaseProps> = () => {
  const [data, setData] = useState<MyData[]>([]);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
//  const [isEnd, setIsEnd] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentRecord, setCurrentRecord] = useState<MyData | null>(null);
  const [form] = Form.useForm();
    const [refreshLoading, setRefreshLoading] = useState(false);
    const [disableRefresh, setDisableRefresh] = useState(false);
    const [savingLayoutPress, setsavingLayoutPress] = useState(false);
    
    const [selectedDatabase, setSelectedDatabase] = useState('none');
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [confirmLoadingDelete, setconfirmLoadingDelete] = useState(false);

    
    
    
    

    
    const fetchData = useCallback(async () => {
        setLoading(true);
//        setIsEnd(false);
        try {
          const collectionRef = collection(db, selectedDatabase);
          const snapshot = await getDocs(collectionRef);
          const dataList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          })) as MyData[];
          dataList.sort((a, b) => (a.layoutOrder ?? 0) - (b.layoutOrder ?? 0));
          setData(dataList);
        } catch (error) {
          notification.error({
            message: 'Error fetching data',
            description: (error as Error).message,
          });
        } finally {
          setLoading(false);
      }
    }, [selectedDatabase]);

    useEffect(() => {
      fetchData();
    }, [fetchData]);

    
    
    
    
    const handleSaveLayout = async () => {
        setsavingLayoutPress(true);
      try {
        const firestoreBatch = writeBatch(db);
        data.forEach((item) => {
          const docRef = doc(db, selectedDatabase, item.id); // Use selectedDatabase
          firestoreBatch.update(docRef, { layoutOrder: item.layoutOrder });
        });
        await firestoreBatch.commit();

        notification.success({ message: 'Layout saved successfully' });
      } catch (error) {
        notification.error({
          message: 'Error saving layout',
          description: (error as Error).message,
        });
      }
        setsavingLayoutPress(false);
    };
    
    
  const handleEdit = (record: MyData) => {
    setCurrentRecord(record);
    form.setFieldsValue(record);
    setIsModalVisible(true);
  };
    
//    const handleRemove = async (record: MyData) => {
//       try {
//         await deleteDoc(doc(db, 'bushcamp', record.id));
//         setData((prevData) => prevData.filter((item) => item.id !== record.id));
//         notification.success({ message: 'Record deleted successfully' });
//       } catch (error) {
//         notification.error({
//           message: 'Error deleting record',
//           description: (error as Error).message,
//         });
//       }
//     };
    
    
    const handleRemove = (record: MyData) => {
      setCurrentRecord(record);
      setIsDeleteModalVisible(true);
    };

    const handleConfirmDelete = async () => {
        setconfirmLoadingDelete(true);
      try {
        if (currentRecord) {
          await deleteDoc(doc(db, selectedDatabase, currentRecord.id));
          setData((prevData) => prevData.filter((item) => item.id !== currentRecord.id));
          notification.success({ message: 'Record deleted successfully' });
        }
      } catch (error) {
        notification.error({
          message: 'Error deleting record',
          description: (error as Error).message,
        });
      } finally {
        setIsDeleteModalVisible(false);
      }
    };

    const handleCancelDelete = () => {
      setIsDeleteModalVisible(false);
        setconfirmLoadingDelete(false);
    };
    
    
    
    
    
    

    const handleSave = async () => {
      try {
        setSubmitting(true);
        const values = await form.validateFields();
        if (currentRecord?.id) {
          await updateDoc(doc(db, selectedDatabase, currentRecord.id), values); // Use selectedDatabase
          setData((prevData) =>
            prevData
              .map((item) =>
                item.id === currentRecord.id ? { ...item, ...values } : item
              )
              .sort((a, b) => (a.layoutOrder ?? 0) - (b.layoutOrder ?? 0))
          );
          notification.success({ message: 'Record updated successfully' });
          setIsModalVisible(false);
          setCurrentRecord(null);
        } else {
          console.error('Document ID is undefined');
        }
      } catch (error) {
        notification.error({
          message: 'Error updating record',
          description: (error as Error).message,
        });
      } finally {
        setSubmitting(false);
      }
    };

  const handleCancel = () => {
    setIsModalVisible(false);
    setCurrentRecord(null);
  };

  const groupDataByStoreroom = (data: MyData[]) => {
    const groupedData: { [key: string]: MyData[] } = {};
    data.forEach((item) => {
      const storeroom = item.section || 'Unknown';
      if (!groupedData[storeroom]) {
        groupedData[storeroom] = [];
      }
      groupedData[storeroom].push(item);
    });
    return groupedData;
  };

  const handleDragEnd = (event: any) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      setData((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);
        const newItems = arrayMove(items, oldIndex, newIndex);
        return newItems.map((item, index) => ({
          ...item,
          layoutOrder: index + 1,
        }));
      });
    }
  };

  const columns: ColumnType<MyData>[] = [
      {
        title: '',
        key: 'drag',
      width: 30,
        render: () => <MenuOutlined style={{ cursor: 'grab' }} />,
      },
      {
        title: '',
        dataIndex: 'layoutOrder',
        key: 'layoutOrder',
  //      sorter: (a: MyData, b: MyData) => (a.layoutOrder ?? 0) - (b.layoutOrder ?? 0),
        defaultSortOrder: 'ascend',
      width: 30,
      },
      {
      title: 'Edit',
      key: 'edit',
     width: 50,
      render: (text: any, record: MyData) => (
        <Button onClick={() => handleEdit(record)}>Edit</Button>
      ),
    },
      {
      title: 'Remove',
      key: 'remove',
      width: 122,
      render: (text: any, record: MyData) => (
            <Button onClick={() => handleRemove(record)}danger>Remove</Button>
      ),
    },
    {
      title: 'Size',
      dataIndex: 'stock_size',
      key: 'stock_size',
      width: 122,
    },
      {
        title: 'Min',
        dataIndex: 'min',
        key: 'min',
        width: 50,
      },
      {
        title: 'Order Size',
        dataIndex: 'wholesale_size',
        key: 'wholesale_size',
        width: 122,
      },
      {
        title: 'Order Location',
        dataIndex: 'location',
        key: 'location',
        width: 122,
      },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
      {
        title: 'Comment',
        dataIndex: 'comment',
        key: 'comment',
      width: 50,
      },

  ];

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    })
  );

    const handleDatabaseChange = (value: string) => {
      setSelectedDatabase(value);
    };
    
    const handleRefresh = useCallback(async () => {
      setRefreshLoading(true);
      setDisableRefresh(true);
      try {
        await fetchData();
        notification.success({ message: 'Data refreshed successfully' });
      } catch (error) {
        notification.error({
          message: 'Error refreshing data',
          description: (error as Error).message,
        });
      } finally {
        setTimeout(() => {
          setRefreshLoading(false);
          setDisableRefresh(false);
        }, 10000); // 10 seconds
      }
    }, [fetchData]);
    
    
  const DraggableRow = (props: any) => {
    const { attributes, listeners, setNodeRef, transform, transition } =
      useSortable({
        id: props['data-row-key'],
      });
    const style = {
      ...props.style,
      transform: CSS.Transform.toString(transform && {
        ...transform,
        scaleY: 1,
      }),
      transition,
    };
    return <tr {...props} ref={setNodeRef} style={style} {...attributes} {...listeners} />;
  };

  const groupedData = groupDataByStoreroom(data);

  return (
    <div>
          
          <Form 
//          initialValues={{ database: "bushcamp"}}>
          initialValues={{ database: "none"}}>
          
          <Form.Item
            name="database"
            label="Select a Database"
          >
          <Select 
          onChange={handleDatabaseChange}
          value={selectedDatabase}
//          defaultValue="bushcamp"
          >
          <Option value="none">None</Option>
            <Option value="bushcamp">Bushcamp</Option>
          <Option value="farmstore">Farmstore</Option>
            <Option value="guestfarm">Guestfarm</Option>
              </Select>
          </Form.Item>
          
          </Form>
          
          
          
          
      {loading ? (
        <Spin
          size="large"
          style={{ justifyContent: 'center', padding: '20px', display: 'flex' }}
        />
//    ) : selectedDatabase === 'none'  (
//       <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ) : (
           
        
           
           <>
           
           {selectedDatabase === 'none' ?
              (
               
//               <p>TIM</p>
               <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
               
               )
               :
               ( 
                
                <>
                <Button type="primary" onClick={handleSaveLayout} disabled={savingLayoutPress} style={{ marginBottom: '1rem' }}>
                         Save Layout
                       </Button>
                     
                <Button
                        type="default"
                        onClick={handleRefresh}
                        style={{ marginBottom: '1rem', marginLeft: '1rem' }}
                        loading={refreshLoading}
                        disabled={disableRefresh}
                      >
                        {refreshLoading ? 'Disabled for 10s...' : 'Refresh'}
                      </Button>
                
                
               {Object.keys(groupedData).map((storeroom) => (
                 <div key={storeroom}>
                   <h3>{storeroom}</h3>
                                                             <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                   <SortableContext items={groupedData[storeroom].map((item) => item.id)}>
                     <Table
                       components={{
                         body: {
                           row: DraggableRow,
                         },
                       }}
                       dataSource={groupedData[storeroom]}
                       columns={columns}
                       rowKey="id"
                       pagination={false}
                       loading={loading}
                       size="small"
                     />
                   </SortableContext>
                                                             </DndContext>
                                                             
                                                             
                 </div>
               ))}
                
                </>
                
                )
           }
       
           
       
           
           
           
                
                
           
      
           </>
      )}

      <Modal
        title="Edit Record"
        open={isModalVisible}
        onOk={handleSave}
        onCancel={handleCancel}
        okButtonProps={{ disabled: submitting }}
        cancelButtonProps={{ disabled: submitting }}
        okText="Save"
        cancelText="Cancel"
      >
        <Form form={form} layout="vertical" name="edit_form">
         
          
          
          <Form.Item
            name="section"
            label="Section"
          >
            <Select>
              <Option value="Fresh">Fresh</Option>
              <Option value="Storeroom">Storeroom</Option>
              <Option value="Deepfreezer">Deepfreezer</Option>
            </Select>
          </Form.Item>
          
          <Form.Item
            name="location"
            label="Location"
          >
            <Select>
          <Option value="ERONGO_WHOLESALE">ERONGO WHOLESALE</Option>
          <Option value="SPAR">SPAR</Option>
          <Option value="OTHER">OTHER</Option>
            </Select>
          </Form.Item>
          
          <Form.Item name="name" label="Name">
            <Input />
          </Form.Item>
          
          <Form.Item name="comment" label="Comment">
            <Input />
          </Form.Item>

          <Form.Item name="wholesale_size" label="Wholesale Size">
            <Input />
          </Form.Item>
          
          <Form.Item name="stock_size" label="Stock Size">
            <Input />
          </Form.Item>
          
          <Form.Item name="min" label="Minimum/Weight">
            <Input />
          </Form.Item>
          
          <Form.Item
            name="layoutOrder"
            label="Layout Order"
            rules={[{ type: 'number', message: 'Please enter a valid number' }]}
          >
            <InputNumber />
          </Form.Item>

        
          
          

          
          
         
          
          
          
          
        </Form>
      </Modal>
          <Modal
            title="Confirm Delete"
          open={isDeleteModalVisible}
            onOk={handleConfirmDelete}
            onCancel={handleCancelDelete}
          okText="Delete"
          okButtonProps={{danger:true}}
          confirmLoading={confirmLoadingDelete}
          >
            <p>Are you sure you want to permanently delete this record?</p>
          </Modal>
    </div>
  );
};

export default EditDatabase;
