// src/App.tsx
import React, { useState, useEffect } from 'react';
import { Layout } from 'antd';
import DashBoard from './Dashboard';
import Header from './Header'; // Import the Header component
import { onAuthStateChanged, User } from 'firebase/auth';
import { auth } from './firebase';
import './App.css';

const { Content, Footer } = Layout;

const App: React.FC = () => {
  const [user, setUser] = useState<User | null>(null);
   

  useEffect(() => {
      
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  return (
          
       
    <Layout className="layout">
      <Header />
      <Content className="content" style={{ padding: '0 50px' }}>
        <div className="site-layout-content" >
          {user ? <DashBoard /> : <p className="intro">Please sign in to continue.</p>}
        </div>
      </Content>
      <Footer className="footer" >
        ©2024 PROVI.NZ
      </Footer>
    </Layout>
        
          
  );
};

export default App;
