// src/Settings.tsx
import React from 'react';

const Overview: React.FC = () => {
  return (
    <div>
      <h2>Overview</h2>
      <p>This is the Overview section of the dashboard.</p>
    </div>
  );
};

export default Overview;
