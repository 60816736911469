// src/Header.tsx
import React, { useEffect, useState } from 'react';
import { Layout, Button, Modal, Form, Input, notification } from 'antd';
import { onAuthStateChanged, signOut, signInWithEmailAndPassword, User } from 'firebase/auth';
import { auth } from './firebase';
import './App.css';
import { Col, Row } from 'antd';


import { Avatar} from 'antd';

//import url from './logo.png';
const url = require( './logo.png');

const { Header: AntHeader } = Layout;

const Header: React.FC = () => {
  const [user, setUser] = useState<User | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  const showSignInModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      notification.success({ message: 'Signed Out' });
    } catch (error) {
      notification.error({ message: 'Error Signing Out', description: (error as Error).message });
    }
  };

  const handleSignIn = async (values: { email: string; password: string }) => {
    setSubmitting(true);
    try {
      await signInWithEmailAndPassword(auth, values.email, values.password);
      notification.success({ message: 'Signed In' });
      setIsModalVisible(false);
    } catch (error) {
      notification.error({ message: 'Error Signing In', description: (error as Error).message });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <AntHeader className="header" >
      <div className="logo" />
          
          
          
          <Row>
          <Col span={6} push={18} style={{justifyContent: 'center', alignItems: 'center', display:'flex'}}>
          
          
          {user ? (
            <Button onClick={handleSignOut} style={{ float: 'right' }}>Sign Out</Button>
          ) : (
            <Button onClick={showSignInModal} style={{ float: 'right' }}>Sign In</Button>
          )}
          
          
            </Col>
            <Col span={18} pull={6}>
          
          
          <Avatar shape="square" size={64} src={url} />
      <h1 style={{  display: 'inline' }}>Omandumba Ordering</h1>
             
          
              
          
          
            </Col>
          </Row>
          
          
          
          
          
   
          
      
          
          
      <Modal
        title="Sign In"
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          name="auth_form"
          onFinish={handleSignIn}
        >
          <Form.Item
            name="email"
            label="Email"
            rules={[{ required: true, message: 'Please input your email!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="password"
            label="Password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" disabled={submitting}>
              Sign In
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </AntHeader>
  );
};

export default Header;
