import React, { useState } from 'react';
import { Tabs } from 'antd';
import Overview from './Overview';
import OrdersToReview from './OrdersToReview';
import PastOrders from './PastOrders';
import SystemSettings from './SystemSettings';
import EditDatabaseView from './EditDatabaseView';
import Farmstore from './FarmstoreView';

const { TabPane } = Tabs;

// Define a type for tab keys
type TabKeys = 'Overview' | 'OrdersToReview' | 'PastOrders' | 'EditDatabaseView' | 'SystemSettings' | 'FarmstoreView';

const Dashboard: React.FC = () => {
  const [activeTab, setActiveTab] = useState<TabKeys>('Overview');
  const [tabKeyCounter, setTabKeyCounter] = useState<number>(0); // State to force re-render of tab components

  const handleTabChange = (activeKey: TabKeys) => {
    setActiveTab(activeKey);
    setTabKeyCounter((prevCounter) => prevCounter + 1); // Update the counter to force re-render
  };

  return (
    <Tabs activeKey={activeTab} onChange={handleTabChange as any}>
      <TabPane tab="Overview" key="Overview">
        <Overview key={`Overview-${tabKeyCounter}`} />
      </TabPane>
      <TabPane tab="Orders to Review" key="OrdersToReview">
        <OrdersToReview key={`OrdersToReview-${tabKeyCounter}`} />
      </TabPane>
      <TabPane tab="Past Orders" key="PastOrders">
        <PastOrders key={`PastOrders-${tabKeyCounter}`} />
      </TabPane>
      <TabPane tab="Edit Database" key="EditDatabaseView">
        <EditDatabaseView key={`EditDatabaseView-${tabKeyCounter}`} />
      </TabPane>
      <TabPane tab="System Settings" key="SystemSettings" >
        <SystemSettings key={`SystemSettings-${tabKeyCounter}`} />
      </TabPane>
          <TabPane tab="Farmstore" key="FarmstoreView" >
            <Farmstore key={`FarmstoreView-${tabKeyCounter}`} />
          </TabPane>
    </Tabs>
  );
};

export default Dashboard;
