// src/SystemSettings.tsx
import React, { useState } from 'react';
import { Button, Modal, Form, Input } from 'antd';
import { doc, updateDoc } from "firebase/firestore";
import { db } from './firebase';

const SystemSettings: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      setIsSubmitting(true);
      // Update Firestore document
      const docRef = doc(db, "system", "access_code");
      await updateDoc(docRef, {
        code: values.accessCode
      });
      setIsModalVisible(false);
      form.resetFields();
    } catch (error) {
      console.error("Failed to update access code: ", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <h2>System Settings</h2>
      <Button type="primary" onClick={showModal}>
        Change Access Code
      </Button>
      <Modal
        title="Update Access Code"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{ disabled: isSubmitting }}
      >
        <Form
          form={form}
          layout="vertical"
          name="accessCodeForm"
          initialValues={{ accessCode: '' }}
        >
          <Form.Item
            name="accessCode"
            label="Access Code"
            rules={[{ required: true, message: 'Please input the access code!' }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default SystemSettings;
