// src/OrdersToReview.tsx
import React, { useEffect, useState } from 'react';
import { List, Button, notification, Spin, Modal, Empty } from 'antd';
import { storage } from './firebase';
import { ref, listAll, getDownloadURL, getMetadata, deleteObject } from 'firebase/storage';
import { DownloadOutlined, DeleteOutlined } from '@ant-design/icons';

interface FileMetadata {
  name: string;
  url: string;
  timeCreated: string;
}

// Define CSS styles for centering the spinner
const spinnerContainerStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '200px', // Adjust the height as needed
};

const PastOrders: React.FC = () => {
  const [groupedFiles, setGroupedFiles] = useState<{ [date: string]: FileMetadata[] }>({});
  const [loading, setLoading] = useState(true);
  const [disabledButtons, setDisabledButtons] = useState<{ [fileName: string]: { isDisabled: boolean, timer: number } }>({});

  useEffect(() => {
    const fetchFiles = async () => {
      setLoading(true);
      try {
        const listRef = ref(storage, 'orders');
        const res = await listAll(listRef);

        const filesWithMetadata = await Promise.all(
          res.items.map(async itemRef => {
            const url = await getDownloadURL(itemRef);
            const metadata = await getMetadata(itemRef);
            return { name: itemRef.name, url, timeCreated: metadata.timeCreated };
          })
        );

        // Group files by date
        const grouped: { [date: string]: FileMetadata[] } = {};
        filesWithMetadata.forEach(file => {
          const date = new Date(file.timeCreated).toISOString().split('T')[0];
          if (!grouped[date]) {
            grouped[date] = [];
          }
          grouped[date].push(file);
        });

        // Sort files within each date group by timeCreated in descending order (newest first)
        Object.keys(grouped).forEach(date => {
          grouped[date].sort((a, b) => new Date(b.timeCreated).getTime() - new Date(a.timeCreated).getTime());
        });

        setGroupedFiles(grouped);
      } catch (error) {
        notification.error({ message: 'Error fetching files', description: (error as Error).message });
      } finally {
        setLoading(false);
      }
    };

    fetchFiles();
  }, []);

  const handleDownload = async (file: FileMetadata) => {
    setDisabledButtons(prev => ({ ...prev, [file.name]: { isDisabled: true, timer: 10 } }));
    
    // Start countdown timer
    const timerInterval = setInterval(() => {
      setDisabledButtons(prev => {
        const newTimer = prev[file.name].timer - 1;
        if (newTimer <= 0) {
          clearInterval(timerInterval);
          return { ...prev, [file.name]: { isDisabled: false, timer: 0 } };
        }
        return { ...prev, [file.name]: { isDisabled: true, timer: newTimer } };
      });
    }, 1000);
    
    try {
      const response = await fetch(file.url);
      if (!response.ok) {
        throw new Error('Failed to download the file');
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = file.name;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      notification.error({ message: 'Error downloading file', description: (error as Error).message });
    }
  };

  const handleRemove = async (file: FileMetadata) => {
    Modal.confirm({
      title: 'Confirm Deletion',
      content: `Are you sure you want to delete ${file.name}?`,
      onOk: async () => {
        try {
          const fileRef = ref(storage, `orders/${file.name}`);
          await deleteObject(fileRef);
          notification.success({ message: 'File deleted successfully' });

          // Refresh the list
          setGroupedFiles(prevGroupedFiles => {
            const newGroupedFiles = { ...prevGroupedFiles };
            const dateGroup = newGroupedFiles[new Date(file.timeCreated).toISOString().split('T')[0]];
            if (dateGroup) {
              newGroupedFiles[new Date(file.timeCreated).toISOString().split('T')[0]] = dateGroup.filter(f => f.name !== file.name);
              if (newGroupedFiles[new Date(file.timeCreated).toISOString().split('T')[0]].length === 0) {
                delete newGroupedFiles[new Date(file.timeCreated).toISOString().split('T')[0]];
              }
            }
            return newGroupedFiles;
          });
        } catch (error) {
          notification.error({ message: 'Error deleting file', description: (error as Error).message });
        }
      },
    });
  };

  const renderContent = () => {
    if (loading) {
      return <Spin size="large" />;
    }

    if (Object.keys(groupedFiles).length === 0) {
      return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
    }

    return Object.keys(groupedFiles)
      .sort((a, b) => new Date(b).getTime() - new Date(a).getTime()) // Sort dates in descending order
      .map(date => (
        <div key={date}>
          <h2>{date}</h2>
          <List
            bordered
            dataSource={groupedFiles[date]}
            renderItem={(item) => (
              <List.Item>
                <a href={item.url} target="_blank" rel="noopener noreferrer">{item.name}</a>
                                   <div style={{ marginLeft: 'auto' }}>
                                   <Button
                  type="primary"
                  shape="round"
                  style={{ marginLeft: '16px' }}
                  icon={<DownloadOutlined />}
                  onClick={() => handleDownload(item)}
                  disabled={disabledButtons[item.name]?.isDisabled}
                >
                  {disabledButtons[item.name]?.isDisabled ? `Wait ${disabledButtons[item.name].timer}s` : 'Download'}
                </Button>
                <Button
                  type="primary"
                  danger
                  shape="round"
                  style={{ marginLeft: '8px' }}
                  icon={<DeleteOutlined />}
                  onClick={() => handleRemove(item)}
                >
                  Remove
                </Button>
                                   </div>
              </List.Item>
            )}
          />
        </div>
      ));
  };

  return (
    <div style={loading ? spinnerContainerStyle : undefined}>
      {renderContent()}
    </div>
  );
};

export default PastOrders;
